/* eslint-disable camelcase */

import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import ExtendedTitle from "../components/extended-title";
import CaseCarousel from "../components/casecarousel";
import Heading from "../components/heading";
import { useCaseOverview } from "../hooks/use-case-overview";
import { Seo } from "../components/seo";

const NotFoundPage = () => {
	const cases = useCaseOverview();

	React.useEffect(() => {
		window.plausible(`404`, {
			props: { path: document.location.pathname },
		});
	}, []);

	return (
		<Layout>
			<main className="">
				<ExtendedTitle
					subtitle="404"
					backButtonTo="/"
					backButtonTitle="Naar start"
				>
					Pagina niet gevonden
				</ExtendedTitle>

				<section className="bg-off-white border-t-2 border-b-2">
					<div className="container mx-auto py-48 text-center">
						<Heading as="h3">
							Ben je op zoek naar een van de volgende pagina's? 🕵️‍♂️
						</Heading>

						<div className="flex gap-4 text-center justify-center mt-8 text-lg">
							<Link to="/portfolio">Portfolio</Link>
							<Link to="/cultuur">Cultuur</Link>
							<Link to="/contact">Contact</Link>
						</div>
					</div>
				</section>
				<CaseCarousel cases={cases} darkBg={true} />
			</main>
		</Layout>
	);
};

export default NotFoundPage;

export const Head = ({ location }) => (
	<Seo
		pathname={location.pathname}
		pageTitle="404"
		title="Niet gevonden - SPARK - fotografie voor cultuur, sport en entertainment"
		description="We konden de pagina die je zocht helaas niet vinden."
	>
		<meta name="robots" content="noindex" />
	</Seo>
);
